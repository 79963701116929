export function formatCash(amount) {
    const isNegative = amount < 0;

    if (typeof amount === 'string') {
        amount = parseFloat(amount.replace(/[^\d.-]/g, ''));
    }

    if (isNaN(amount)) {
        throw new Error('Invalid amount');
    }

    amount = Math.abs(amount) || 0;

    if (amount === 0) {
        return '0';
    }

    const units = generateUnits();
    let result = '';

    for (let i = units.length - 1; i >= 0; i--) {
        const unit = units[i];
        const quotient = Math.floor(amount / unit.value);

        if (quotient > 0) {
            result = `${result}${quotient}${unit.label} `;
            amount %= unit.value;
        }
    }

    return (isNegative ? '-' : '') + result.trim();
}

function generateUnits() {
    const units = [];
    let value = 1;
    const labels = ['', '만', '억', '조', '경'];

    for (let i = 0; i < labels.length; i++) {
        units.push({value, label: labels[i]});
        value *= 10000;
    }

    return units;
}

// function oldFormatCash(amount) {
//     const isNegative = amount < 0;
//
//     if (typeof amount === 'string') {
//         amount = parseFloat(amount.replace(/[^\d.]/g, ''));
//     }
//
//     if (isNaN(amount)) {
//         throw new Error('Invalid amount');
//     }
//
//     amount = Math.abs(amount) || 0;
//
//     if (amount === 0) {
//         return '0';
//     }
//
//     const tenThousand = 10000;
//     const units = [
//         {value: tenThousand * tenThousand, label: '억'},
//         {value: tenThousand, label: '만'},
//     ];
//
//     let result = '';
//
//     for (const unit of units) {
//         const quotient = Math.floor(amount / unit.value);
//         if (quotient > 0 || result.length > 0) {
//             result += (result.length > 0 ? ' ' : '') + quotient + unit.label;
//             amount %= unit.value;
//         }
//     }
//
//     if (amount > 0 || result.length === 0) {
//         result += (result.length > 0 ? ' ' : '') + amount;
//     }
//
//     return isNegative ? '-' + result : result;
// }
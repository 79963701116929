export function isMobileDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iOS 장치
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true;
    }

    // 안드로이드 장치
    if (/android/i.test(userAgent)) {
        return true;
    }

    // 윈도우 폰 장치
    if (/windows phone/i.test(userAgent)) {
        return true;
    }

    return /(webos|blackberry|windows phone|opera mini|iemobile)/i.test(userAgent);
}

export const convertToDateString = date =>
    date.toLocaleDateString('ko-KR')
        .replace(/\.\s?$/, '')
        .replace(/\.\s?/g, '-')
        .split('-')
        .map((token, i) => i === 1 ? `0${token}`.slice(-2) : token)
        .join('-');

export function safeNumberConversion(str) {
    str = `${str}`;

    if (!str.match(/^[-+]?(\d+\.?\d*|\.\d+)$/)) {
        throw new Error("Invalid number format");
    }

    if (str.indexOf('.') === -1) {
        const num = parseInt(str, 10);
        if (isNaN(num)) {
            throw new Error("Invalid integer");
        }
        return num;
    }

    const num = parseFloat(str);
    if (isNaN(num)) {
        throw new Error("Invalid float");
    }
    return num;
}

export function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
}

/**
 * 숫자를 지정된 소수점 자릿수 이하로 버림 처리하는 함수
 * @param {number} num - 원본 숫자
 * @param {number} decimalPlaces - 소수점 자릿수
 * @returns {number} - 버림 처리된 숫자
 */
export function truncateToDecimalPlaces(num, decimalPlaces) {
    const factor = Math.pow(10, decimalPlaces);
    return Math.floor(num * factor) / factor;
}

/**
 * 특정 속성과 값을 가진 객체의 인덱스를 찾는 함수
 * @param array
 * @param property
 * @param value
 * @returns {*}
 */
export function findIndexByProperty(array, property, value) {
    return array.findIndex(obj => obj[property] === value);
}
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        players: {}, // Store players' emotions and timers
        maxPlayers: 6
    },
    getters: {
        emotions: state => state.players,
    },
    mutations: {
        SET_EMOTION(state, {playerId, emotion, timer}) {
            Vue.set(state.players, playerId, {emotion, timer, canChange: false});
        },
        CLEAR_EMOTION(state, playerId) {
            Vue.delete(state.players, playerId);
        },
        ENABLE_EMOTION_CHANGE(state, playerId) {
            if (state.players[playerId]) {
                state.players[playerId].canChange = true;
            }
        }
    },
    actions: {
        setEmotion({commit, state, dispatch}, {playerId, emotion}) {
            if (Object.keys(state.players).length >= state.maxPlayers) {
                return;
            }

            if (state.players[playerId] && !state.players[playerId].canChange) {
                return;
            }

            dispatch('clearEmotion', playerId);

            const timer = setTimeout(() => {
                dispatch('clearEmotion', playerId);
            }, 3000);

            commit('SET_EMOTION', {playerId, emotion, timer});

            setTimeout(() => {
                commit('ENABLE_EMOTION_CHANGE', playerId);
            }, 1000);
        },
        clearEmotion({commit, state}, playerId) {
            const player = state.players[playerId];
            if (player && player.timer) {
                clearTimeout(player.timer);
            }

            commit('CLEAR_EMOTION', playerId);
        }
    }
};

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {EventBus} from '../eventBus';
import gameStore from './game';
import userStore from './user';
import configStore from './config';
import emotionStore from './emotion';
import {setNickname} from "../plugins/apiHelper_game";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        game: gameStore,
        user: userStore,
        emotion: emotionStore,
        config: configStore,
    },
    state: {
        loginToken: undefined,
        sessionData: undefined,
        window: {w: 0, h: 0},
        rsvFold: false,
        rsvCall: false,
        sound: false,
        maintenanceNotice: undefined,
        isAnyModalOpened: false,
        isConnected: false,
        isOnline: false,
        networkUnavailable: false,
        networkErrorCount: 0,
        isNotSetPlayerName: false,
        appVisibility: 'visible',
        timestampOnHidden: 0,
        CLIENT_APP_HIDDEN_TIMEOUT: 5000,
    },
    getters: {
        token: state => state.loginToken,
        sessionData: state => state.sessionData,
        appVisibility: state => state.appVisibility,
        timestampOnHidden: state => state.timestampOnHidden,
        isOnline: state => state.isOnline,
        isConnected: state => state.isConnected,
        networkErrorCount: state => state.networkErrorCount,
        networkUnavailable: state => state.networkUnavailable,
        maintenanceNotice: state => state.maintenanceNotice,
        isAnyModalOpened: state => state.isAnyModalOpened,
        isSetPlayerName: state => state.sessionData && (state.sessionData.name || '').toString().trim().length,
        appHiddenTimeout: state => state.CLIENT_APP_HIDDEN_TIMEOUT,
    },
    mutations: {
        login(state, data) {
            state.sessionData = data.info
            state.loginToken = data.loginToken
        },
        SET_IS_ANY_MODAL_OPENED(state, value) {
            state.isAnyModalOpened = value;
        },
        SET_IS_CONNECTED(state, status) {
            state.isConnected = status;
        },
        SET_IS_ONLINE(state, status) {
            state.isOnline = status;
        },
        SET_IS_NETWORK_UNSTABLE(state, status) {
            state.networkUnavailable = status;
        },
        RESET_NETWORK_ERROR_COUNT(state) {
            state.networkErrorCount = 0;
        },
        INCREASE_NETWORK_ERROR_COUNT(state) {
            if (state.networkErrorCount > 3) {
                state.isConnected = false;
                return;
            }

            state.networkErrorCount++;
        },
        SET_APP_VISIBILITY(state, visibleState) {
            state.appVisibility = visibleState;
        },
        UPDATE_TIMESTAMP_ON_HIDDEN(state) {
            state.timestampOnHidden = new Date().getTime();
        }
    },
    actions: {
        auth_check({commit}) {
            if (!this.state.loginToken) return false
            return axios.post('/auth/check', {loginToken: localStorage.getItem('loginToken')}).then(res => {
                if (res.data.ret == 0) commit('login', res.data)
                return res.data.ret == 0
            })
        },
        SOCK_poker(ctx, data) {
            EventBus.$emit(`poker-${data.type}`, data.body);
        },
        SOCK_token({state}, token) {
            state.loginToken = token;
        },
        SOCK_duplicated() {
            EventBus.$emit('socket-duplicated')
        },
        SOCK_cut() {
            EventBus.$emit('socket-cut')
        },
        SOCK_maintenance(ctx, data) {
            this.state.maintenanceNotice = data.body.msg
        },
        SOCK_common(ctx, data) {
            EventBus.$emit(`common-${data.type}`, data.body);
        },
        async setPlayerName({state}, name) {
            return await setNickname({loginToken: state.loginToken, name});
        }
    },
})

export const PlayState = {
    PLAYING: 0,
    FOLD: 1,
    WAIT: 2,
    SITOUT: 3,
    LOGOUT: 4,
    NONE: 5,
    WAITACCEPTBUYIN: 6,
    OBSERVE: 7,
    DUMMY: 8,
    ADMIN: 9,
    WAIT_FROM_ENTER: 10,
    WAIT_FROM_SMALL: 11,
    WAIT_FROM_BIG: 12,
    WAIT_FROM_DEALDER: 13,
    WAIT_FROM_OTHER_PLAYER: 14,
    LEAVE_ROOM_RESERVED: 15,
}

export const RoomState = {
    WaitPlayer: 0,
    Deal: 1,
    FreeFlopAction: 2,
    FlopAction: 3,
    TurnAction: 4,
    RiverAction: 5,
    OnlyOne: 6,
    ShowResult: 7,
    Showdown: 8,
    WaitNextRound: 9,
    WaitOtherPlayer: 10,
    BreakTime: 11,
    Stop: 12,
}

export const GameStatus = {
    BeforeStart: 0,
    Ready: 1,
    Playing: 2,
    Pause: 3,
    End: 4
}

export const GameType = {
    Relay: 0,
    Tournament: 1
}

export const GameBlindType = {
    ROUND: 0,
    BREAKTIME: 1
}
export function getGameStatusSort(state){
    switch (Number(state)) {
        case GameStatus.Ready:
            return 0
        case GameStatus.Playing:
            return 1
        case GameStatus.BeforeStart:
            return 2
        case GameStatus.End:
            return 3
        case GameStatus.Pause:
            return 4
    }
}

export function getPlayStateStr(state) {
    switch (Number(state)) {
        case PlayState.PLAYING:
            return 'PlayState.PLAYING'
        case PlayState.FOLD:
            return 'PlayState.FOLD'
        case PlayState.WAIT:
            return 'PlayState.WAIT'
        case PlayState.SITOUT:
            return 'PlayState.SITOUT'
        case PlayState.LOGOUT:
            return 'PlayState.LOGOUT'
        case PlayState.NONE:
            return 'PlayState.NONE'
        case PlayState.WAITACCEPTBUYIN:
            return 'PlayState.WAITACCEPTBUYIN'
        case PlayState.OBSERVE:
            return 'PlayState.OBSERVE'
        case PlayState.DUMMY:
            return 'PlayState.DUMMY'
    }
}

export function getRoomStateStr(state) {
    switch (Number(state)) {
        case RoomState.WaitPlayer:
            return 'RoomState.WaitPlayer'
        case RoomState.Deal:
            return 'RoomState.Deal'
        case RoomState.FreeFlopAction:
            return 'RoomState.FreeFlopAction'
        case RoomState.FlopAction:
            return 'RoomStateFlopAction'
        case RoomState.TurnAction:
            return 'RoomState.TurnAction'
        case RoomState.RiverAction:
            return 'RoomState.RiverAction'
        case RoomState.OnlyOne:
            return 'RoomState.OnlyOne'
        case RoomState.ShowResult:
            return 'RoomState.ShowResult'
        case RoomState.WaitNextRound:
            return 'RoomState.WaitNextRound'
    }
}

export function getGameStatusStr(state) {
    switch (Number(state)) {
        case GameStatus.BeforeStart:
            return '생성 요청'
        case GameStatus.Ready:
            return '공개'
        case GameStatus.Playing:
            return '게임 중'
        case GameStatus.Pause:
            return '일시 정지'
        case GameStatus.End:
            return '종료'
    }
}

export function getTournamentGameStatusStr(state) {
    switch (Number(state)) {
        case GameStatus.BeforeStart:
            return '대기'
        case GameStatus.Ready:
            return '진행전'
        case GameStatus.Playing:
            return '진행중'
        case GameStatus.Pause:
            return '중지'
        case GameStatus.End:
            return '종료'
    }
}

export function getGameTypeByRouteName(routeName){
    switch (routeName) {
        case 'game':
            return GameType.Relay
        case 'tournament':
            return GameType.Tournament
        default:
            return GameType.Relay
    }
}

export function getRouteNameByGameType(gameType){
    switch (gameType) {
        case GameType.Relay:
            return 'game'
        case GameType.Tournament:
            return 'tournament'
        default:
            return 'game'
    }
}

export function getGameTypeStrByGameType(gameType){
    switch (gameType) {
        case GameType.Relay:
            return 'relay'
        case GameType.Tournament:
            return 'tournament'
        default:
            return 'relay'
    }
}

export function getRealPos(baselineIdx, playerIdx, limitPlayUserCnt) {
    if (baselineIdx < 0) return playerIdx
    let idx = playerIdx - baselineIdx;
    if (idx < 0) idx += limitPlayUserCnt;
    return idx;
}

export function convNum(num) {
    if (num < 10000) return num;
    return `${Math.floor(num / 10000)}만` + (num % 10000 == 0 ? '' : ' ' + num % 10000);
}

export function getTimemmss(secs) {
    var minutes = Math.floor(secs / 60);
    secs = secs % 60;
    minutes = minutes % 60;

    return `${("0" + minutes).slice(-2)}:${("0" + secs).slice(-2)}`;
}
export function pokerErrorProc(type, body) {
    if (Number(body.ret) == -100) {
        return `토큰이 만료되었습니다`
    }else if( Number(body.ret) == -900 ){
        return `데이터 검증 오류`
    } else {
        switch (type) {
            case 'enter':
                return errorEnterProc(body)
            case 'seat':
                return errorSeatProc(body)
            case 'buyin':
                return errorBuyinProc(body)
            case 'addon':
                return errorAddonProc(body)
            case 'observe':
                return errorObserveProc(body)
            case 'out':
                return errorOutProc(body)
            default:
                return `알 수 없는 타입 오류 - ${type} / ${body}`
        }
    }
}

function errorEnterProc(body) {
    switch (Number(body.ret)) {
        case -1:
            return `방이 존재하지 않습니다. 방 번호 : ${body.roomCode}`
        case -2:
            return `잘못된 방 비밀번호 입니다`
        case -3:
            return `다른 위치에서 접속했습니다`
        case -4:
            return `잘못된 접근입니다`
        case -5:
            return `최대 관전자 수를 초과했습니다`
        case -6:
            return `비공개 방은 입장할 수 없습니다`
        case -7:
            return `관리자 계정으로 입장할 수 없는 방 입니다`
        case -8:
            return `존재하지 않은 관리자 계정입니다.`
        case -9:
            return `존재하지 않은 유저 정보입니다`
        case -10:
            return `다른방에서 관전중 입니다`
        case -11:
            return `다른방에서 플레이중 입니다`
        case -12:
            return `방입장에 실패하였습니다. 관리자에게 문의해주세요.`
        default:
            return `알 수 없는 오류 ${JSON.stringify(body)}`
    }
}

function errorSeatProc(body) {
    switch (Number(body.ret)) {
        case -1:
            return `방이 존재하지 않습니다.`
        case -2:
            return `게임이 중지 된 상태입니다`
        case -3:
            return `점검중입니다. 잠시후 다시 이용해주세요`
        case -101:
            return `게임이 종료 된 상태입니다`
        case -102:
            return `자리비움 횟수를 초과하거나 강퇴당하여 참여할 수 없습니다`
        case -103:
            return `플레이어 정보를 찾을 수 없습니다`
        case -104:
            return `관전상태가 아닙니다`
        case -105:
            return `티켓이 부족합니다`
        case -106:
            return `빈자리가 없습니다`
        case -107:
            return `게임 데이터 저장에 실패했습니다`
        case -108:
            return `헤즈업 상황에서는 참여할 수 없습니다`
        case -109:
            return `지정한 자리에 다른 플레이어가 존재합니다.`
        case -110:
            return `더이상 참여 불가능한 토너먼트 입니다`
        case -901: // 자리 선택 예약
            return `이미 예약 중인 자리입니다. 다른 자리를 선택해 주세요.`
        case -902: // 자리 선택 예약
            return `선택한 자리에 이미 다른 플레이어가 존재합니다.`
        case -909: // 자리 선택 예약
            return `유효하지 않은 요청 입니다.`
        case -1001:
            return `칩이 남아있습니다`
        case -1002:
            return `더블 바이인이 허용되어있지 않습니다`
        case -1003:
            return `더블 리바이인이 허용되어있지 않습니다`
        case -1004:
            return `게임에 참여할 수 없는 블라인드 레벨입니다`
        case -1005:
            return `인당 리바이인 횟수를 초과 했습니다`
        case -1006:
            return `테이블 엔트리 횟수를 초과 했습니다`
        case -2000:
            return `게임 참여 중 오류가 발생했습니다. 다시 시도해주세요`
        case -2001: // (클라이언트 데이터 조작 가능성) 요청 데이터와 실제 게임 데이터가 다른 경우
            return `잘못된 요청 입니다`
        case -2002: // 요청한 바이인 금액보다 실제 유저 캐시가 부족한 경우
            return `게임 참여를 위한 포인트가 부족합니다`
        case -2003: // 최대 바이인 금액보다 높은 금액을 요청한 경우
            return `최대 바이인 금액을 초과 했습니다`
        default:
            return '알 수 없는 오류'
    }
}

function errorBuyinProc(body) {
    switch (Number(body.ret)) {
        case -1:
            return `방이 존재하지 않습니다.`
        case -2:
            return `게임이 중지 된 상태입니다`
        case -101:
            return `게임이 종료 된 상태입니다`
        case -102:
            return `자리비움 횟수를 초과하여 바이인을 할 수 없습니다`
        case -103:
            return `플레이어 정보를 찾을 수 없습니다`
        case -104:
            return `참여하지 않은 상태에서는 바이인을 할 수 없습니다`
        case -1001:
            return `칩이 남아있습니다`
        case -1002:
            return `더블 바이인이 허용되어있지 않습니다`
        case -1003:
            return `더블 리바이인이 허용되어있지 않습니다`
        case -1004:
            return `게임에 참여할 수 없는 블라인드 레벨입니다`
        case -1005:
            return `인당 리바이인 횟수를 초과 했습니다`
        case -1006:
            return `테이블 엔트리 횟수를 초과 했습니다`
        case -1007:
            return `티켓이 부족합니다`
        default:
            return '알 수 없는 오류'
    }
}

function errorAddonProc(body) {
    switch (Number(body.ret)) {
        case -1:
            return `방이 존재하지 않습니다.`
        case -2:
            return `게임이 중지 된 상태입니다`
        case -101:
            return `게임이 종료 된 상태입니다`
        case -102:
            return `자리비움 횟수를 초과하여 참여할 수 없습니다`
        case -103:
            return `플레이어 정보를 찾을 수 없습니다`
        case -1001:
            return `칩이 없습니다`
        case -1002:
            return `더 이상 애드온 할 수 없습니다`
        case -1003:
            return `더블 애드온이 허용되어있지 않습니다`
        case -1004:
            return `브레이크 타임이 아닙니다`
        case -1005:
            return `애드온이 허용되어있지 않습니다`
        case -1006:
            return `테이블 엔트리 횟수를 초과 했습니다.`
        case -1007:
            return `인당 리바이인 횟수를 초과 했습니다`
        case -1008:
            return `티켓이 부족합니다`
        default:
            return '알 수 없는 오류'
    }
}

function errorObserveProc(body) {
    switch (Number(body.ret)) {
        case -1:
            return `방이 존재하지 않습니다.`
        case -2:
            return `게임이 중지 된 상태입니다`
        case -3:
            return `바이인 할 수 없습니다`
        default:
            return '알 수 없는 오류'
    }
}

function errorOutProc(body) {
    switch (Number(body.ret)) {
        case -1:
            return `플레이어를 찾을 수 없습니다`
        case -2:
            return `토너먼트 진행중에는 나가기가 불가능 합니다`
        default:
            return `알 수 없는 오류 ${JSON.stringify(body)}`
    }
}
import * as gameCommon from './gameCommon'
import {Howl, Howler} from 'howler'

class PokerCommon {
    suit = ["s", "d", "h", "c"];
    rank = ["A", "K", "Q", "J", "T", "9", "8", "7", "6", "5", "4", "3", "2"];
    cardUrl = [];
    chipUrl = [];
    posCoord = [
        {left: '39%', top: '80%'},
        {left: '2%', top: '67%'},
        {left: '2%', top: '47%'},
        {left: '2%', top: '27%'},
        {left: '24%', top: '7%'},
        {right: '24%', top: '7%'},
        {right: '2%', top: '27%'},
        {right: '2%', top: '47%'},
        {right: '2%', top: '67%'}
    ];

    posCoordLandscape = [
        {left: '39%', top: '70%'},
        {left: '22%', top: '67%'},
        {left: '5%', top: '55%'},
        {left: '5%', top: '27%'},
        {left: '29%', top: '7%'},
        {right: '29%', top: '7%'},
        {right: '5%', top: '27%'},
        {right: '5%', top: '55%'},
        {right: '22%', top: '67%'}
    ];

    posBetCoord = [
        {left: '39%', top: '76%'},
        {left: '25%', top: '73%'},
        {left: '25%', top: '50%'},
        {left: '25%', top: '30%'},
        {left: '25%', top: '23%'},
        {right: '26%', top: '23%'},
        {right: '25%', top: '30%'},
        {right: '25%', top: '50%'},
        {right: '25%', top: '73%'}
    ];

    posBetCoordLandscape = [
        {left: '46%', top: '66%'},
        {left: '25%', top: '63%'},
        {left: '19%', top: '55%'},
        {left: '19%', top: '35%'},
        {left: '32%', top: '30%'},
        {right: '35%', top: '30%'},
        {right: '19%', top: '35%'},
        {right: '19%', top: '55%'},
        {right: '25%', top: '63%'}
    ];

    posChatCoord = [
        {left: '39%', top: '72%'},
        {left: '25%', top: '69%'},
        {left: '25%', top: '46%'},
        {left: '25%', top: '26%'},
        {left: '25%', top: '19%'},
        {right: '26%', top: '19%'},
        {right: '25%', top: '26%'},
        {right: '25%', top: '46%'},
        {right: '25%', top: '69%'}
    ];

    posChatCoordLandscape = [
        {left: '40%', top: '72%'},
        {left: '19%', top: '69%'},
        {left: '2%', top: '57%'},
        {left: '2%', top: '31%'},
        {left: '26%', top: '11%'},
        {right: '25%', top: '11%'},
        {right: '2%', top: '31%'},
        {right: '2%', top: '57%'},
        {right: '18%', top: '69%'}
    ];

    color = [
        '239,154,154', '159,168,218', '128,203,196', '165,214,167', '255,224,130', '129,212,250', '176,190,197', '215,204,200', '241,248,233'
    ]

    sound = {};
    isSoundOn = false

    soundbg = {};
    isLoadBg = false;

    isShowChat = true;

    isShowColorCard = false;

    constructor() {
        this.init();
    }

    init() {
        this.chipUrl.push(require(`./assets/poker/chip_1.png`));
        this.chipUrl.push(require(`./assets/poker/chip_5.png`));
        this.chipUrl.push(require(`./assets/poker/chip01.png`));
        this.chipUrl.push(require(`./assets/poker/chip02.png`));
        this.chipUrl.push(require(`./assets/poker/chip03.png`));
        this.chipUrl.push(require(`./assets/poker/chip04.png`));
        this.chipUrl.push(require(`./assets/poker/chip05.png`));
        this.chipUrl.push(require(`./assets/poker/chip06.png`));
    }

    loadHowler(callback) {
        const list = [
            {name: 'enter', src: '/sound/enter.mp3'},
            {name: 'allin', src: '/sound/VoiceAllIn.mp3'},
            {name: 'bet', src: '/sound/VoiceBet.mp3'},
            {name: 'call', src: '/sound/VoiceCall.mp3'},
            {name: 'check', src: '/sound/VoiceCheck.mp3'},
            {name: 'fold', src: '/sound/VoiceFold.mp3'},
            {name: 'raise', src: '/sound/VoiceRaise.mp3'},
            {name: 'win', src: '/sound/SoundWinPot.mp3'},
            {name: 'winbaksu', src: '/sound/SoundWinBaksu.mp3'},
            {name: 'myturn', src: '/sound/myturn.mp3'},
            {name: 'bg', src: '/sound/bg.mp3', loop: true},
            {name: 'cardhook', src: '/sound/cardhook.mp3'},
            {name: 'cardsheck', src: '/sound/cardsheck.mp3'},
            {name: 'cardssg', src: '/sound/cardssg.mp3'},
            {name: 'chipdrop', src: '/sound/chipdrop.mp3'},
            {name: 'allinbg', src: '/sound/allinbg.wav'},
            {name: 'countdown', src: '/sound/countdown.mp3', loop: true},
            {name: 'showdown', src: '/sound/showdown.mp3'},
            {name: 'showdownvoice', src: '/sound/showdownvoice.mp3'},

            {name: 'flush', src: '/sound/hand/flush.mp3'},
            {name: 'fourofakind', src: '/sound/hand/fourofakind.mp3'},
            {name: 'fullhouse', src: '/sound/hand/fullhouse.mp3'},
            {name: 'highcard', src: '/sound/hand/highcard.mp3'},
            {name: 'onepair', src: '/sound/hand/onepair.mp3'},
            {name: 'royalflush', src: '/sound/hand/royalflush.mp3'},
            {name: 'straightflush', src: '/sound/hand/straightflush.mp3'},
            {name: 'straight', src: '/sound/hand/straight.mp3'},
            {name: 'threeofakind', src: '/sound/hand/threeofakind.mp3'},
            {name: 'twopairs', src: '/sound/hand/twopairs.mp3'}
        ]

        list.forEach(it => {
            this.addSound(it.name, it.src, callback, it.loop ? true : false)
        })

        Howler.autoUnlock = true;
    }

    changeVolue(value) {
        Howler.volume(value);
    }

    addSound(name, src, unlockcallback, loop = false) {
        const sound = new Howl({
            src: src,
            onunlock: unlockcallback,
            buffer: true,
            loop: loop,
            onplayerror() {
                sound.once('unlock', () => {
                    sound.play()
                })
            }
        })

        this.sound[name] = sound
    }

    getCardUrl(nick) {

        let cardUrl = 'card';

        if (this.isShowColorCard) {
            cardUrl = 'card4color'
        }

        let cardResult = `/img/poker/${cardUrl}/${nick}.png`;

        if (nick == 'backh') {
            cardResult = this.imgChange(cardResult);
        }

        return cardResult;
    }

    getChipUrl(idx) {
        return this.chipUrl[idx]
    }

    getPosCoord(idx, isLand = false) {
        return isLand ? this.posCoordLandscape[idx] : this.posCoord[idx];
    }

    getBetPosCoord(idx, isLand = false) {
        return isLand ? this.posBetCoordLandscape[idx] : this.posBetCoord[idx];
    }

    getChatPosCoord(idx, isLand = false) {
        return isLand ? this.posChatCoordLandscape[idx] : this.posChatCoord[idx];
    }

    convert(num) {
        if (num < 1000) return num;
        if (num % 1000 == 0) return `${(num / 1000).toFixed(0)}k`;
        if (num % 100 == 0) return `${(num / 1000).toFixed(1)}k`;
        return `${(num / 1000).toFixed(2)}k`;
    }

    buyin(sock, sn) {
        sock.emit('poker-buyin', {sn: sn});
    }

    start(sock, sn) {
        sock.emit('poker-start', {sn: sn});
    }

    fold(sock, sn) {
        sock.emit('poker-action', {sn: sn, action: 'fold'});
    }

    call(sock, sn) {
        sock.emit('poker-action', {sn: sn, action: 'call'});
    }

    playSound(name) {
        try {
            if (!this.isSoundOn) return
            this.sound[name].play()
        } catch (e) {
            console.log('play sound error', name, e);
        }
    }

    playSoundBg(name) {
        try {
            if (!this.isLoadBg) return
            this.sound[name].play()
        } catch (e) {
            console.log('play sound error', name, e);
        }
    }

    playSoundLoop(name) {
        try {
            if (!this.isSoundOn) return
            this.sound[name].loop = true;

            return this.sound[name].play();
        } catch (e) {
            console.log('play sound error', name, e);
        }
    }

    stopSound(name) {
        try {
            return this.sound[name].pause();
        } catch (e) {
            console.log('play sound error', name, e);
        }
    }

    showChat(show) {
        this.isShowChat = show;
    }

    getShowChat() {
        return this.isShowChat;
    }

    isBlockChatUser(name) {
        var blockChatUser = localStorage.getItem("blockChatUser");
        var blockChatUserArr = JSON.parse(blockChatUser);

        let isBlockChatUser = false;

        if (blockChatUserArr != null) {
            blockChatUserArr.forEach(it => {
                if (it == name) {
                    isBlockChatUser = true;
                }
            });
        }

        return isBlockChatUser;
    }

    showColorCard() {
        this.isShowColorCard = !this.isShowColorCard
    }

    isVictory() {
        return (location.host.toLowerCase().indexOf('victory') >= 0) ? true : false
    }

    imgChange(img) {

        if (this.isVictory()) {
            img = img.replace(".png", "_victory.png");
            img = img.replace(".jpg", "_victory.jpg");
        }

        return img;
    }

    getGameTitle() {
        let host = location.host;
        let gameTitle = '고고';

        if (host.toLowerCase().indexOf('victory') >= 0) {
            gameTitle = '빅토리';
        }

        return gameTitle;
    }
}

// ["Kd","5c"]
export default new PokerCommon();

export function getCallStr(roominfo, mydata) {
    if (mydata.betCash == roominfo.callBet) return '체크';
    const callCash = roominfo.callBet - mydata.betCash > mydata.cash ? mydata.cash : roominfo.callBet - mydata.betCash;

    let callName = '콜';

    if (callCash == mydata.cash) {
        callName = '올인';
    }

    return `${callName}(${gameCommon.convNum(callCash)})`;
}
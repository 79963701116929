import {GameStatus, RoomState, PlayState, getRoomStateStr, getGameStatusStr, GameType} from "../gameCommon";

export default {
    namespaced: true,
    state: {
        useDebug: false,
        isDebugging: false,
        gameWindow: null,
        roundKey: -1,
        heartbeat: 0,
        tWaitTurn: 0,
        remainSec: 0,
        remainSecMs: 0,
        roomInfo: {},
        gameInfo: {},
        playersInfo: [],
        playerSeats: [],
        myInfo: {},
        lastRoomState: -1,
        turnCountdown: 0,
        foldReserved: false,
        callReserved: false,
        refs: {
            pot: null,
        },
        lastAction: null,
        tableHandRanking: '',
        soundConfig: false,
        tableStack: 0,
        bestHandDetails: null,
        isDealPhase: false,
        dealingCardCount: 0,
        validPlayerCountAtDeal: 0,
        playerSocketIssues: [],
        tournamentInfo:{},
        isRelayGame: false,
        isTournamentGame: false,
    },
    getters: {
        useDebug: state => state.useDebug === 'true',
        isDebugging: state => state.isDebugging,
        gameWindow: state => state.gameWindow,
        roundKey: state => state.roundKey,
        heartbeat: state => state.heartbeat,
        tWaitTurn: state => state.tWaitTurn,
        remainSec: state => state.remainSec,
        remainSecMs: state => state.remainSecMs,
        roomInfo: state => state.roomInfo,
        gameInfo: state => state.gameInfo,
        playersInfo: state => state.playersInfo,
        playerSeats: state => state.playerSeats,
        myInfo: state => state.myInfo,
        currentRoomState: state => state.roomInfo.state,
        gameStatus: state => state.roomInfo.gameStatus,
        remainingTurnTime: state => state.roomInfo.tRemainTurn,
        isPaused: state => state.roomInfo.gameStatus === GameStatus.Pause,
        isGameOver: state => state.roomInfo.gameStatus === GameStatus.End,
        isPlaying: state => state.roomInfo.gameStatus === GameStatus.Playing,
        roomStateName: state => getRoomStateStr(state.roomInfo.state),
        gameStatusName: state => getGameStatusStr(state.roomInfo.gameStatus),
        lastRoomState: state => state.lastRoomState,
        countdown: state => state.turnCountdown,
        canPlayGame: state => state.playersInfo.length > 1
            ? state.playersInfo.filter(player => player.playState == PlayState.PLAYING).length > 1
            : false,
        isShowResult: state => state.roomInfo.state === RoomState.ShowResult,
        isShowdown: state => state.roomInfo.state === RoomState.Showdown,
        isValidShowdown: state => state.roomInfo.state == RoomState.Showdown && state.lastRoomState !== RoomState.Showdown,
        isDeal: state => state.roomInfo.state == RoomState.Deal,
        isCallReserved: state => state.callReserved,
        isFoldReserved: state => state.foldReserved,
        potRef: state => state.refs.pot,
        lastAction: state => state.lastAction,
        soundConfig: state => state.soundConfig,
        tableStack: state => state.tableStack,
        bestHandDetails: state => state.bestHandDetails,
        tableHandRanking: state => state.tableHandRanking,
        isDealPhase: state => state.isDealPhase,
        dealingCardCount: state => state.dealingCardCount,
        playerSocketIssues: state => state.playerSocketIssues,
        tournamentInfo: state => state.tournamentInfo,
        isRelayGame: state => state.gameInfo.type === GameType.Relay,
        isTournamentGame: state => state.gameInfo.type === GameType.Tournament,
    },
    mutations: {
        USE_DEBUG(state, status) {
            state.useDebug = status;
        },
        SET_IS_DEBUGGING(state, status) {
            state.isDebugging = state.useDebug && status;
        },
        SET_GAME_WINDOW(state, _window) {
            state.gameWindow = _window;
        },
        UPDATE_ROUND_ID(state, roundKey) {
            state.roundKey = roundKey;
        },
        UPDATE_HEARTBEAT(state) {
            state.heartbeat = new Date().getTime();
        },
        SET_T_WAIT_TURN(state, timestamp) {
            state.tWaitTurn = timestamp;
        },
        SET_TURN_REMAINS(state, seconds) {
            state.remainSec = seconds;
        },
        SET_TURN_REMAINS_MS(state, ms) {
            state.remainSecMs = ms;
        },
        SET_HEARTBEAT(state, heartbeat) {
            state.heartbeat = heartbeat;
        },
        SET_ROOM_INFO(state, roomInfo) {
            state.roomInfo = roomInfo;
        },
        SET_GAME_INFO(state, gameInfo) {
            state.gameInfo = gameInfo;
        },
        SET_TOURNAMENT_INFO(state, tournamentInfo) {
            state.tournamentInfo = tournamentInfo;
        },
        SET_PLAYERS_INFO(state, playersInfo) {
            state.playersInfo = playersInfo;
        },
        SET_MY_INFO(state, myInfo) {
            state.myInfo = {...myInfo};
        },
        SET_LAST_ROOM_STATE(state, lastRoomState) {
            state.lastRoomState = lastRoomState;
        },
        SET_PLAYER_SEAT_POSITION(state, {playerIndex, playerPosition, playerRef}) {
            const existingIndex = state.playerSeats.findIndex(p => p.playerIndex === playerIndex);

            if (existingIndex === -1) {
                state.playerSeats.push({playerIndex, playerPosition, playerRef});
                return;
            }

            state.playerSeats.splice(existingIndex, 1, {playerIndex, playerPosition, playerRef});
        },
        SET_TURN_COUNTDOWN(state, value) {
            state.turnCountdown = value;
        },
        SET_RESERVE_CALL(state, status) {
            state.callReserved = status;
        },
        SET_RESERVE_FOLD(state, status) {
            state.foldReserved = status;
        },
        SET_REF(state, {key, ref}) {
            state.refs[key] = ref;
        },
        SET_LAST_ACTION(state, lastAction) {
            state.lastAction = lastAction;
        },
        SET_TABLE_STACK(state, value) {
            state.tableStack = value > 0 ? value : state.tableStack;
        },
        SET_BEST_HAND_DETAILS(state, value) {
            state.bestHandDetails = value;
        },
        SET_DEALING_CARD_COUNT(state, value) {
            if (value === 0) {
                state.dealingCardCount = 0;
            } else {
                state.dealingCardCount++;
            }
        },
        SET_IS_DEAL_PHASE(state) {
            state.isDealPhase = state.dealingCardCount;
        },
        SET_VALID_PLAYER_COUNT_AT_DEAL(state, count) {
            state.validPlayerCountAtDeal = count;
        },
        SET_TABLE_HAND_RANKING(state, value) {
            state.tableHandRanking = value;
        },
        ADD_PLAYER_SOCKET_ISSUE(state, playerSn) {
            const player = state.playerSocketIssues.find(player => player?.sn === playerSn);

            if (!player) {
                state.playerSocketIssues.push(player);
            }
        },
        REMOVE_PLAYER_SOCKET_ISSUE(state, playerSn) {
            const issueIndex = state.playerSocketIssues.findIndex(player => player?.sn === playerSn);

            if (issueIndex === -1) {
                return;
            }

            state.playerSocketIssues.splice(issueIndex, 1);
        }
    },
    actions: {
        resetForDealPhase({state, commit}) {
            commit('SET_VALID_PLAYER_COUNT_AT_DEAL', state.playersInfo.filter(player => player.playState === PlayState.PLAYING));
            commit('SET_DEALING_CARD_COUNT', 0);
            commit('SET_IS_DEAL_PHASE', false);
        },
        reset({commit}) {
            commit('SET_TABLE_HAND_RANKING', '');
            commit('SET_BEST_HAND_DETAILS', null);
            commit('SET_LAST_ACTION', null);

            commit('SET_RESERVE_CALL', false);
            commit('SET_RESERVE_FOLD', false);
        }
    }
}
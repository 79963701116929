import {getRandomWord} from './randomWord';

export const isDevelopment = process.env.NODE_ENV === 'development';

const _LOG_SERVER = {
    url: process.env.VUE_APP_LOG_SERVER,
    port: process.env.VUE_APP_LOG_SERVER_PORT,
}

export const disabledLogger = () => {
};

export async function logger(message, messageId = '') {
    if (!isDevelopment) {
        return;
    }

    // pass heartbeat
    if (messageId.indexOf('heartbeat') !== -1) {
        return;
    }

    fetch(`http://${_LOG_SERVER.url}:${_LOG_SERVER.port}/log`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            messageId: messageId || await getRandomWord(),
            message: message,
        }),
    });
}

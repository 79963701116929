import store from "../store";

const requireAuth = () => async (to, from, next) => {
    if (to.query.token) {
        localStorage.setItem(`loginToken`, to.query.token);
        store.state.loginToken = to.query.token
    }

    if (from.query.token) {
        localStorage.setItem(`loginToken`, from.query.token);
        store.state.loginToken = from.query.token
    }

    const isAuth = await store.dispatch('auth_check');

    if (isAuth || to.path === `/login`) {
        next();
    } else {
        next(`/login?return=${to.fullPath}`);
    }
}

export {requireAuth};
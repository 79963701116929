import {EventBus} from '@/eventBus'
import store from './store'

export function poker_enter(sock, body) {
    return new Promise((resolve, reject) => {
        EventBus.$on(`poker-enter-ret`, body => {
            if (body.ret >= 0) resolve(body)
            else reject(body)
            EventBus.$off(`poker-enter-ret`)
        })
        body.token = store.state.loginToken
        sock.emit(`poker`, {type: `enter`, body: body})
    })
}

export function poker_seat(sock, payload) {
    return new Promise((resolve, reject) => {
        EventBus.$on(`poker-seat-ret`, body => {
            if (body.ret == 0) resolve(body)
            else reject(body)
            EventBus.$off(`poker-seat-ret`)
        })
        const body = {...payload, isBuyin2x: false}
        body.token = store.state.loginToken
        sock.emit(`poker`, {type: `seat`, body: body})
    })
}

export function poker_buyin(sock, roomCode, is2x) {
    return new Promise((resolve, reject) => {
        EventBus.$on(`poker-buyin-ret`, body => {
            if (body.ret == 0) resolve(body)
            else reject(body)
            EventBus.$off(`poker-buyin-ret`)
        })
        const body = {roomCode: roomCode, buyIn2x: is2x}
        body.token = store.state.loginToken
        sock.emit(`poker`, {type: `buyin`, body: body})
    })
}



export function poker_action_call(sock, roomCode, idx) {
    return new Promise((resolve, reject) => {
        EventBus.$on(`poker-call-ret`, body => {
            if (body.ret == 0) resolve(body)
            else reject(body)
            EventBus.$off(`poker-call-ret`)
        })
        const body = {idx: idx, roomCode: roomCode, action: 'call'}
        body.token = store.state.loginToken
        sock.emit(`poker`, {type: `action`, body: body})
    })
}

export function poker_action_fold(sock, roomCode, idx) {
    return new Promise((resolve, reject) => {
        EventBus.$on(`poker-call-ret`, body => {
            if (body.ret == 0) resolve(body)
            else reject(body)
            EventBus.$off(`poker-call-ret`)
        })
        const body = {idx: idx, roomCode: roomCode, action: 'fold'}
        body.token = store.state.loginToken
        sock.emit(`poker`, {type: `action`, body: body})
    })
}

export function poker_action_raise(sock, roomCode, idx, raiseCash) {
    return new Promise((resolve, reject) => {
        EventBus.$on(`poker-call-ret`, body => {
            if (body.ret == 0) resolve(body)
            else reject(body)
            EventBus.$off(`poker-call-ret`)
        })
        const body = {idx: idx, roomCode: roomCode, action: 'raise', cash: raiseCash}
        body.token = store.state.loginToken
        sock.emit(`poker`, {type: `action`, body: body})
    })
}

export function poker_change_user_state(sock, roomCode, action, msg) {
    return new Promise((resolve, reject) => {
        EventBus.$on(`poker-change-user-state-ret`, body => {
            if (body.ret == 0) resolve(body)
            else reject(body)
            EventBus.$off(`poker-change-user-state-ret`)
        })
        const body = {roomCode: roomCode}
        body.token = store.state.loginToken
        body.action = action

        if (msg) {
            body.msg = msg;
        }

        sock.emit(`poker`, {type: `change-user-state`, body: body})
    })
}

export function poker_chat(sock, roomCode, msg) {
    return new Promise((resolve, reject) => {
        EventBus.$on(`poker-change-user-state-ret`, body => {
            if (body.ret == 0) resolve(body)
            else reject(body)
            EventBus.$off(`poker-change-user-state-ret`)
        })
        const body = {roomCode: roomCode}
        body.token = store.state.loginToken
        body.action = `chat`
        body.msg = msg
        sock.emit(`poker`, {type: `change-user-state`, body: body})
    })
}

export function poker_emotion(sock, roomCode, data) {
    return new Promise((resolve, reject) => {
        const returnId = `poker-user-emotion-ret`;
        EventBus.$on(returnId, body => {
            if (body.ret === 0) {
                resolve(body);
            } else {
                reject(body);
            }
            EventBus.$off(returnId);
        });

        const body = {
            roomCode,
            token: store.state.loginToken,
            action: 'emotion',
            data,
        }

        sock.emit(`poker`, {type: `user-emotion`, body: body});
    });
}


export function poker_out(sock, roomCode) {
    return new Promise((resolve, reject) => {
        EventBus.$on(`poker-out-ret`, body => {
            if (body.ret == 0) resolve(body)
            else reject(body)
            EventBus.$off(`poker-out-ret`)
        })
        const body = {roomCode: roomCode}
        body.token = store.state.loginToken
        sock.emit(`poker`, {type: `out`, body: body})
    })
}

export function poker_get_gameinfo(sock, body) {
    return new Promise((resolve, reject) => {
        EventBus.$on(`poker-get-gameinfo-ret`, body => {
            if (body.ret == 0) resolve(body)
            else reject(body)
            EventBus.$off(`poker-get-gameinfo-ret`)
        })
        body.token = store.state.loginToken
        sock.emit(`poker`, {type: `get-gameinfo`, body: body})
    })
}

// export function poker_seat_reservation(sock, roomCode, data) {
//     return new Promise((resolve, reject) => {
//         const actionId = `seat-reservation`;
//         const returnId = `poker-${actionId}-ret`;
//
//         EventBus.$on(returnId, body => {
//             if (body.ret === 0) {
//                 resolve(body);
//             } else {
//                 reject(body);
//             }
//             EventBus.$off(returnId);
//         });
//
//         const body = {
//             roomCode,
//             token: store.state.loginToken,
//             action: actionId,
//             data,
//         }
//
//         sock.emit(`poker`, {type: actionId, body: body});
//     });
// }

// export function poker_release_seat_reservation(sock, roomCode, data) {
//     return new Promise((resolve, reject) => {
//         const actionId = `release-seat-reservation`;
//         const returnId = `poker-${actionId}-ret`;
//
//         EventBus.$on(returnId, body => {
//             if (body.ret === 0) {
//                 resolve(body);
//             } else {
//                 reject(body);
//             }
//             EventBus.$off(returnId);
//         });
//
//         const body = {
//             roomCode,
//             token: store.state.loginToken,
//             action: actionId,
//             data,
//         }
//
//         sock.emit(`poker`, {type: actionId, body: body});
//     });
// }

// export function poker_addon(sock, roomCode, isAddon2x) {
//     return new Promise((resolve, reject) => {
//         EventBus.$on(`poker-addon-ret`, body => {
//             if (body.ret == 0) resolve(body)
//             else reject(body)
//             EventBus.$off(`poker-addon-ret`)
//         })
//         const body = {roomCode: roomCode, addOn2x: isAddon2x}
//         body.token = store.state.loginToken
//         sock.emit(`poker`, {type: `addon`, body: body})
//     })
// }

// export function poker_observe(sock, roomCode) {
//     return new Promise((resolve, reject) => {
//         EventBus.$on(`poker-observe-ret`, body => {
//             if (body.ret == 0) resolve(body)
//             else reject(body)
//             EventBus.$off(`poker-observe-ret`)
//         })
//         const body = {roomCode: roomCode}
//         body.token = store.state.loginToken
//         sock.emit(`poker`, {type: `observe`, body: body})
//     })
// }

// export function poker_get_playerinfo(sock, roomCode) {
//     return new Promise((resolve, reject) => {
//         EventBus.$on(`poker-get-playerinfo-ret`, body => {
//             if (body.ret == 0) resolve(body)
//             else reject(body)
//             EventBus.$off(`poker-get-playerinfo-ret`)
//         })
//         const body = {roomCode: roomCode}
//         body.token = store.state.loginToken
//         sock.emit(`poker`, {type: `get-playerinfo`, body: body})
//     })
// }

// export function poker_get_leaderboard(sock, roomCode) {
//     return new Promise((resolve, reject) => {
//         EventBus.$on(`poker-get-leaderboard-ret`, body => {
//             if (body.ret == 0) resolve(body)
//             else reject(body)
//             EventBus.$off(`poker-get-leaderboard-ret`)
//         })
//         const body = {roomCode: roomCode}
//         body.token = store.state.loginToken
//         sock.emit(`poker`, {type: `get-leaderboard`, body: body})
//     })
// }

// export function poker_get_record(sock, roomCode) {
//     return new Promise((resolve, reject) => {
//         EventBus.$on(`poker-get-record-ret`, body => {
//             if (body.ret == 0) resolve(body)
//             else reject(body)
//             EventBus.$off(`poker-get-record-ret`)
//         })
//         const body = {roomCode: roomCode}
//         body.token = store.state.loginToken
//         sock.emit(`poker`, {type: `get-record`, body: body})
//     })
// }

// export function poker_start(sock, roomCode) {
//     return new Promise((resolve, reject) => {
//         EventBus.$on(`poker-start-ret`, body => {
//             if (body.ret == 0) resolve(body)
//             else reject(body)
//             EventBus.$off(`poker-start-ret`)
//         })
//         const body = {roomCode: roomCode}
//         body.token = store.state.loginToken
//         sock.emit(`poker`, {type: `start`, body: body})
//     })
// }

// export function poker_stop(sock, roomCode) {
//     return new Promise((resolve, reject) => {
//         EventBus.$on(`poker-stop-ret`, body => {
//             if (body.ret == 0) resolve(body)
//             else reject(body)
//             EventBus.$off(`poker-stop-ret`)
//         })
//         const body = {roomCode: roomCode}
//         body.token = store.state.loginToken
//         sock.emit(`poker`, {type: `stop`, body: body})
//     })
// }
//
// export function poker_end(sock, roomCode) {
//     return new Promise((resolve, reject) => {
//         EventBus.$on(`poker-end-ret`, body => {
//             if (body.ret == 0) resolve(body)
//             else reject(body)
//             EventBus.$off(`poker-end-ret`)
//         })
//         const body = {roomCode: roomCode}
//         body.token = store.state.loginToken
//         sock.emit(`poker`, {type: `end`, body: body})
//     })
// }

// export function poker_force_kick(sock, idx, roomCode) {
//     return new Promise((resolve, reject) => {
//         EventBus.$on(`poker-kick-ret`, body => {
//             if (body.ret == 0) resolve(body)
//             else reject(body)
//             EventBus.$off(`poker-kick-ret`)
//         })
//         const body = {idx: idx, roomCode: Number(roomCode)}
//         body.token = store.state.loginToken
//         sock.emit(`poker`, {type: `kick`, body: body})
//     })
// }

// export function poker_modify_user_cash(sock, idx, cash, roomCode) {
//     return new Promise((resolve, reject) => {
//         EventBus.$on(`poker-modify-user-cash-ret`, body => {
//             if (body.ret == 0) resolve(body)
//             else reject(body)
//             EventBus.$off(`poker-modify-user-cash-ret`)
//         })
//         const body = {idx: Number(idx), cash: Number(cash), roomCode: Number(roomCode)}
//         body.token = store.state.loginToken
//         sock.emit(`poker`, {type: `modify-user-cash`, body: body})
//     })
// }
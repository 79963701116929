import {getGamePlayDates, getGameHistory, getGameWinners, changeAvatar} from "@/plugins/apiHelper_game";
import {convertToDateString} from "@/utils/tools";
import {poker_seat_reservation, poker_release_seat_reservation} from "@/gameSock";

export default {
    namespaced: true,
    state: {
        isShownBuyinPopup: false,
        targetHistoryDate: convertToDateString(new Date()),
        gamePlayDates: [],
        gameHistory: [],
        gameWinners: [],
        current: 0,
        handHistoryModal: false,
        handHistoryDetailsModal: false,
        // 플레이어의 방 나가기 예약
        isGameExitReserved: false,
        // 플레이어 자리 선택 예약
        seatReservations: {},
    },
    getters: {
        isShownBuyinPopup: state => state.isShownBuyinPopup,
        targetHistoryDate: state => state.targetHistoryDate,
        gamePlayDates: state => state.gamePlayDates,
        gameHistory: state => state.gameHistory,
        gameWinners: state => state.gameWinners,
        current: state => state.current,
        isGameExitReserved: state => state.isGameExitReserved,
        // seatReservations: state => state.seatReservations,
        handHistoryModal: state => state.handHistoryModal,
        handHistoryDetailsModal: state => state.handHistoryDetailsModal,
    },
    mutations: {
        SET_BUYIN_MODAL_VISIBILITY(state, visible) {
            state.isShownBuyinPopup = visible;
        },
        SET_TARGET_HISTORY_DATE(state, date) {
            state.targetHistoryDate = date;
        },
        SET_GAME_PLAY_DATES(state, dates) {
            const oldItems = state.gamePlayDates;

            if (!(dates && dates.length)) {
                state.gamePlayDates.splice(0, oldItems.length, ...[state.targetHistoryDate]);
                return
            }

            state.gamePlayDates.splice(0, oldItems.length, ...dates);

            if (state.targetHistoryDate !== dates[0]) {
                state.targetHistoryDate = dates[0];
            }
        },
        SET_GAME_HISTORY(state, history) {
            const oldItems = state.gameHistory;
            state.gameHistory.splice(0, oldItems.length, ...history);
        },
        SET_WINNERS(state, winners) {
            const oldItems = state.gameHistory;
            state.gameWinners.splice(0, oldItems.length, ...winners);
        },
        SET_CURRENT(state, value) {
            state.current = value;
        },
        SET_HAND_HISTORY_MODAL(state, value) {
            state.handHistoryModal = value;
        },
        SET_HAND_HISTORY_DETAILS_MODAL(state, value) {
            state.handHistoryDetailsModal = value;
        },
        SET_IS_GAME_EXIT_RESERVED(state, value) {
            state.isGameExitReserved = value;
        },
        SET_SEAT_RESERVATION(state, {position, status}) {
            state.seatReservations[position] = status;
        },
    },
    actions: {
        async fetchGamePlayDates({commit}, token) {
            const result = await getGamePlayDates({loginToken: token});
            commit('SET_GAME_PLAY_DATES', result.data || []);
        },
        async fetchGameHistory({commit}, {token, date}) {
            const result = await getGameHistory({token, date});
            commit('SET_GAME_HISTORY', result.data || []);
        },
        async fetchGameWinners({commit}, {token, game_sn, game_uuid}) {
            const result = await getGameWinners({token, game_sn, game_uuid});

            if (result) {
                commit('SET_WINNERS', result.data.winners || []);
            }
        },
        /**
         * src/components/Game/PlayerWrap.vue
         * @param commit
         * @param $socket
         * @param roomSn
         * @param data
         * @returns {Promise<void>}
         */
        async reserveSeatPosition({commit}, {$socket, roomSn, data}) {
            const result = await poker_seat_reservation($socket, roomSn, data);

            if (result.ret === 0) {
                const {position} = result.data;
                commit('SET_SEAT_RESERVATION', {status: true, position});
            }
        },
        async releaseSeatPositionReservation({commit}, {$socket, roomSn, data}) {
            const result = await poker_release_seat_reservation($socket, roomSn, data);

            if (result.ret === 0) {
                const {position} = result.data;
                commit('SET_SEAT_RESERVATION', {status: true, position});
            }
        },
        async changeAvatar(_, {token, avatarNumber}) {
            return await changeAvatar({token, avatarNumber});
        }
    },
}

export async function getRandomWord() {
    try {
        const response = await fetch('https://random-word-api.herokuapp.com/word?number=1');
        const [word] = await response.json();
        return word;
    } catch (error) {
        console.error('Error fetching random word:', error);
        return 'unknown';
    }
}

import pokerCommon from '@/pokerCommon';
import Cookies from 'js-cookie';

export const __CONFIG_TYPES__ = {
    volume: 'volume',
    tableType: 'tableType',
    cardType: 'cardType',
    sound: 'sound',
    hideBrowserWarningFor7DaysStartedAt: 'hideBrowserWarningFor7DaysStartedAt',
}

export const __CONFIG_DEFAULTS__ = {
    volume: 50,
    sound: 1,
    tableType: 1,
    cardType: 1,
    cookie: {
        expires: 7,
        path: '/',
        domain: `.${process.env.VUE_APP_HOST}`,
        secure: (process.env.NODE_ENV !== "development")
    }
}

function getSavedSoundConfig() {
    let value = Cookies.get(__CONFIG_TYPES__.sound);

    if (!value) {
        Cookies.set(__CONFIG_TYPES__.sound, __CONFIG_DEFAULTS__.sound, __CONFIG_DEFAULTS__.cookie);
        value = Cookies.get(__CONFIG_TYPES__.sound);
    }

    return parseInt(value);
}

function getSavedVolume() {
    let value = Cookies.get(__CONFIG_TYPES__.volume);

    if (!value) {
        Cookies.set(__CONFIG_TYPES__.volume, __CONFIG_DEFAULTS__.volume, __CONFIG_DEFAULTS__.cookie);
        value = Cookies.get(__CONFIG_TYPES__.volume);
    }

    return parseInt(value);
}

function getSavedTableType() {
    let value = Cookies.get(__CONFIG_TYPES__.tableType, {domain: `.${process.env.VUE_APP_HOST}`});

    if (!value) {
        Cookies.set(__CONFIG_TYPES__.tableType, __CONFIG_DEFAULTS__.tableType, __CONFIG_DEFAULTS__.cookie);
        value = Cookies.get(__CONFIG_TYPES__.tableType);
    }

    return parseInt(value);
}

function getSavedCardType() {
    let value = Cookies.get(__CONFIG_TYPES__.cardType);

    if (!value) {
        Cookies.set(__CONFIG_TYPES__.cardType, __CONFIG_DEFAULTS__.cardType, __CONFIG_DEFAULTS__.cookie);
        value = Cookies.get(__CONFIG_TYPES__.cardType);
    }

    return parseInt(value);
}

function getSavedHideBrowserWarningFor7DaysStartedAt() {
    const value = Cookies.get(__CONFIG_TYPES__.hideBrowserWarningFor7DaysStartedAt);

    if (value === null) {
        return 0;
    }

    return parseInt(value);
}

export default {
    namespaced: true,
    state: {
        sound: getSavedSoundConfig(),
        volume: getSavedVolume(),
        tableType: getSavedTableType(),
        cardType: getSavedCardType(),
        hideBrowserWarningFor7DaysStartedAt: getSavedHideBrowserWarningFor7DaysStartedAt(),
        tableTypeCount: 4,
        cardTypeCount: 3,
    },
    getters: {
        sound: state => state.sound,
        volume: state => state.volume,
        tableType: state => state.tableType,
        cardType: state => state.cardType,
        hideBrowserWarningFor7DaysStartedAt: state => state.hideBrowserWarningFor7DaysStartedAt,
        cardResourceNumber: state => `0${state.cardType}`.slice(-2),
        tableResourceNumber: state => `0${state.tableType}`.slice(-2),
        TABLE_TYPE_COUNT: state => state.tableTypeCount,
        CARD_TYPE_COUNT: state => state.cardTypeCount,
    },
    mutations: {
        SET_SOUND(state, value) {
            state.sound = value;
            pokerCommon.isSoundOn = !!value;
            Cookies.set(__CONFIG_TYPES__.sound, value, __CONFIG_DEFAULTS__.cookie);
        },
        SET_VOLUME(state, value) {
            state.volume = value;
            Cookies.set(__CONFIG_TYPES__.volume, value, __CONFIG_DEFAULTS__.cookie);
        },
        SET_TABLE_TYPE(state, value) {
            state.tableType = value;
            Cookies.set(__CONFIG_TYPES__.tableType, value, __CONFIG_DEFAULTS__.cookie);
        },
        SET_CARD_TYPE(state, value) {
            state.cardType = value;
            Cookies.set(__CONFIG_TYPES__.cardType, value, __CONFIG_DEFAULTS__.cookie);
        },
        SET_HIDE_BROWSER_WARNING_FOR_7DAYS_STARTED_AT(state, value) {
            state.hideBrowserWarningFor7DaysStartedAt = value;
            Cookies.set(__CONFIG_TYPES__.hideBrowserWarningFor7DaysStartedAt, value, __CONFIG_DEFAULTS__.cookie);
        }
    },
    actions: {
        toggleSound({state, commit}) {
            const currentConfig = state.sound;
            commit('SET_SOUND', currentConfig === 0 ? 1 : 0);
        },
        async reset({commit}) {
            commit('SET_VOLUME', __CONFIG_DEFAULTS__.volume);
            commit('SET_TABLE_TYPE', __CONFIG_DEFAULTS__.tableType);
            commit('SET_CARD_TYPE', __CONFIG_DEFAULTS__.cardType);
        },
    },
}
import axios from 'axios'
import store from '../store'

export function getMainData(formData) {
    return new Promise((resolve, reject) => {
        axios.post('/pokerAPI/getMainData', formData).then(res => {
            if (res.data.ret == 0) resolve(res.data);
            else reject(res.data.ret);
        })
    })
}

export function getRoomList(formData) {
    return new Promise((resolve, reject) => {
        axios.post('/pokerAPI/getRoomList', formData).then(res => {
            if (res.data.ret == 0) resolve(res.data);
            else reject(res.data.ret);
        })
    })
}

export function checkRoomCode(formData) {
    return new Promise((resolve, reject) => {
        formData.token = store.state.loginToken
        axios.post('/pokerAPI/checkRoomCode', formData).then(res => {
            if (res.data.ret >= 0) resolve(res.data);
            else reject(res.data);
        })
    })
}

export function enter(formData) {
    return new Promise((resolve, reject) => {
        axios.post('/pokerAPI/enter', formData).then(res => {
            if (res.data.ret == 0) resolve(res.data);
            else reject(res.data.ret);
        })
    })
}

export function checkRoomEnter(formData) {
    return new Promise((resolve, reject) => {
        axios.post('/pokerAPI/checkRoomEnter', formData).then(res => {
            if (res.data.ret == 0) resolve(res.data);
            else reject(res.data.ret);
        })
    })
}

export function getGamePlayDates(formData) {
    return new Promise((resolve, reject) => {
        axios.post('/pokerAPI/getGamePlayDates', formData).then(res => {
            if (res.data.ret === 0) {
                resolve(res.data);
            } else {
                reject(res.data.ret);
            }
        });
    })
}

export function getGameHistory(formData) {
    return new Promise((resolve, reject) => {
        axios.post('/pokerAPI/getGameHistory', formData).then(res => {
            if (res.data.ret === 0) {
                resolve(res.data);
            } else {
                reject(res.data.ret);
            }
        });
    })
}

export function getGameWinners(formData) {
    return new Promise((resolve, reject) => {
        axios.post('/pokerAPI/winners', formData).then(res => {
            if (res.data.ret === 0) {
                resolve(res.data);
            } else {
                reject(res.data.ret);
            }
        });
    })
}

// 아바타 변경
export function changeAvatar(data) {
    return new Promise((resolve, reject) => {
        axios.post('/pokerAPI/avatar', data).then(res => {
            if (res.data.ret === 0) {
                resolve(res.data);
            } else {
                reject(res.data.ret);
            }
        });
    })
}

export function setNickname(formData) {
    return new Promise((resolve, reject) => {
        axios.post('/pokerAPI/nickname', formData).then(res => {
            if (res.data.ret === 0) resolve(res.data);
            else reject(res.data);
        })
    })
}

export function getPlayerRecord(formData) {
    return new Promise((resolve, reject) => {
        axios.post('/pokerAPI/getPlayerRecord', formData).then(res => {
            if (res.data.ret === 0) resolve(res.data);
            else reject(res.data);
        })
    })
}
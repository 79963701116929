import Vue from 'vue'
import VueRouter from 'vue-router'
import routesV2 from './routes-v2';

Vue.use(VueRouter)

const routes = [
    // v2
    ...routesV2,
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    next();
})

export default router